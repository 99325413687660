import PropTypes from "prop-types"
import React from "react"
import { Flex, Text, Button } from "rebass/styled-components"
import { withTheme } from "styled-components"
import Container from "./UI/Container"
import Link from "./UI/Link"
import { useStaticQuery, graphql } from "gatsby"
import Section from "./UI/Section"

const FooterRaw = ({ menusSettings, logoAlt }) => {
  logoAlt = logoAlt || "Logo"
 

  if (!menusSettings.activeFooter) {
    return <></>
  }
  return (
    <Section bg="footer" color="#fff">
      <Container>
        <Flex alignItems="center" flexDirection={["column", "row"]}>
          {menusSettings.footermenu.map((item, i) => (
            <Link
              activeClassName="active"
              href={item.path}
              external={item.external}
              key={"menu-" + i}
            >
              <Button variant="ninja">
                <Text pl={[0, 3]} pb={2}>
                  {item.title}
                </Text>
              </Button>
            </Link>
          ))}
        </Flex>
      </Container>
    </Section>
  )
}

FooterRaw.propTypes = {
  logoAlt: PropTypes.string,
  menusSettings: PropTypes.shape({
    logo: PropTypes.any,
    footermenu: PropTypes.array,
    activeFooter: PropTypes.bool,
  }),
  theme: PropTypes.shape({
    space: PropTypes.any,
  }),
}

const Footer = ({ theme }) => {
  const { markdownRemark, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        markdownRemark(frontmatter: { title: { eq: "menus" } }) {
          frontmatter {
            activeFooter
            logo {
              publicURL
              childImageSharp {
                fixed(height: 50, quality: 64) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            footermenu {
              offset
              path
              title
              external
            }
          }
        }
      }
    `
  )

  const menusSettings = markdownRemark.frontmatter || {}
  return <FooterRaw theme={theme} menusSettings={menusSettings} logoAlt={site.siteMetadata.title} />
}

Footer.propTypes = {
  theme: PropTypes.any,
}

export { FooterRaw }
export default withTheme(Footer)
