import PropTypes from "prop-types"
import React, { useState } from "react"
import { Box, Flex, Text, Button } from "rebass/styled-components"
import { withTheme } from "styled-components"
import Container from "./UI/Container"
import Icofont from "./UI/Icofont"
import Link from "./UI/Link"
import { useStaticQuery, graphql } from "gatsby"
import CustomImage from "./UI/CustomImage"
import loadable from "@loadable/component"

const Sticky = loadable(() => import("react-stickynode"))
const Drawer = loadable(() => import("./Drawer"))

const NavbarRaw = ({ theme, menusSettings, logoAlt }) => {
  logoAlt = logoAlt || "Logo"
  const [stiked, setStiked] = useState(false)
  const [open, setOpen] = useState(false)
  const toggleHandler = () => {
    setOpen(!open)
  }
  if (!menusSettings.activeTopMenu) {
    return <></>
  }
  return (
    <Sticky top={0} innerZ={5} onStateChange={status => setStiked(status.status > 0)}>
      <Box variant="navbar" className={stiked ? "sticky" : "sticky-off"}>
        <Container>
          <Flex alignItems="center" minHeight={["70px"]}>
            <Box>
              <Link href="/">
                <CustomImage
                  width={["70px"]}
                  height="auto"
                  py={2}
                  img={stiked ? menusSettings.logo : menusSettings.logo2}
                  alt={logoAlt}
                />
              </Link>
            </Box>
            <Box mx="auto" />
            <Box alignItems="center" display={["none", "none", "flex"]}>
              {menusSettings.mainmenu
                .filter(item => item.inNavBar)
                .map((item, i) => (
                  <Link
                    activeClassName="active"
                    href={item.path}
                    external={item.external}
                    key={"menu-" + i}
                  >
                    <Button variant="ninja">
                      <Text pl={[0, 0, 3]}>{item.title}</Text>
                    </Button>
                  </Link>
                ))}
            </Box>
            <Drawer
              closeButton={
                <Box textAlign="right">
                  <Button variant="ninja" p={3}>
                    <Icofont icon="close" size={2} />
                  </Button>
                </Box>
              }
              placement="right"
              drawerHandler={
                <Button variant="ninja" pl={2} display={["block", "block", "none"]}>
                  <Icofont icon="navigation-menu" size={2} />
                </Button>
              }
              open={open}
              toggleHandler={toggleHandler}
            >
              <Box
                width={["100vw", "100vw", "400px"]}
                bg="background"
                sx={{
                  ".active button": {
                    position: "relative",
                    "&:after": {
                      content: '""',
                      position: "absolute",
                      height: "3px",
                      width: "30px",
                      bottom: "5px",
                      left: "50%",
                      borderRadius: "3px",
                      marginLeft: "-15px",
                      backgroundColor: "primary",
                    },
                  },
                }}
              >
                {menusSettings.mainmenu
                  .filter(item => item.inSideBar)
                  .map((item, i) => (
                    <Link href={item.path} external={item.external} onClick={toggleHandler} key={i}>
                      <Button
                        variant="ninja"
                        px={3}
                        py={3}
                        width="100%"
                        textAlign={["center", "center", "left"]}
                      >
                        <Text pl={[0, 0, 3]}>{item.title}</Text>
                      </Button>
                    </Link>
                  ))}
              </Box>
            </Drawer>
          </Flex>
        </Container>
      </Box>
    </Sticky>
  )
}

NavbarRaw.propTypes = {
  logoAlt: PropTypes.string,
  menusSettings: PropTypes.shape({
    logo: PropTypes.any,
    logo2: PropTypes.any,
    mainmenu: PropTypes.any,
    activeTopMenu: PropTypes.bool,
  }),
  theme: PropTypes.shape({
    space: PropTypes.any,
  }),
}

const Navbar = ({ theme }) => {
  const { markdownRemark, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        markdownRemark(frontmatter: { title: { eq: "menus" } }) {
          frontmatter {
            activeTopMenu
            logo2 {
              publicURL
              childImageSharp {
                fluid(maxHeight: 50, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            logo {
              publicURL
              childImageSharp {
                fluid(maxHeight: 50, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            mainmenu {
              external
              inNavBar
              inSideBar
              offset
              path
              title
            }
          }
        }
      }
    `
  )

  const menusSettings = markdownRemark.frontmatter || {}
  return <NavbarRaw theme={theme} menusSettings={menusSettings} logoAlt={site.siteMetadata.title} />
}

Navbar.propTypes = {
  theme: PropTypes.any,
}

export { NavbarRaw }
export default withTheme(Navbar)
