import colors from "./colors"
import buttons from "./buttons"
import variants from "./variants"
import forms from "./forms"
import { theme as themeSettings } from "../cms/data/settings"

const theme = {
  colors: colors,
  forms: forms,
  fonts: {
    body: themeSettings.font.fontname + ", sans-serif",
    heading: themeSettings.fontheads.fontname + ", sans-serif",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 22, 24, 28, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  space: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100, 128, 256, 512],
  sizes: {
    avatar: 48,
  },
  radii: {
    square: 0,
    default: 4,
    big: 10,
    circle: 99999,
  },
  shadows: {
    card: "0 0 4px rgba(0, 0, 0, .125)",
  },
  text: {
    heading: {
      fontWeight: "700",
      fontSize: [5, 6, 7],
    },
    h1: {
      variant: "heading",
      fontWeight: "bold",
      fontSize: [7, 8, 9],
    },
    h3: {
      variant: "heading",
      fontSize: [2, 3],
    },
    display: {
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: [5, 6, 7],
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em",
      fontSize: "inherit",
    },
  },
  variants: variants,
  buttons: buttons,
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
  },
  breakpoints: Object.values(themeSettings.breakpoints),
  gutter: 30,
}
export default theme
