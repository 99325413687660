import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import PropTypes from "prop-types";

const Page = ({ children, title, description }) => {
  return (
    <Layout>
      <SEO title={title} description={description} />
      {children}
    </Layout>
  );
};
Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string
};
export default Page;
